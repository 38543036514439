<template>
    <div class="page-body">
        <div class="table-body">
            <div class="table-header">
                <div class="handle-box">
                    <table width="100%">
                        <tr>
                            <td style="width: 80px;">
                                申请编号：
                            </td>
                            <td>
                                <el-input v-model="searchItem.applyNum"
                                          size="small" placeholder="请输入申请编号"></el-input>
                            </td>
                            <td style="width: 80px">
                                售后工程师：
                            </td>
                            <td>
                                <el-select size="small" v-model="searchItem.afterSalesEngineerStr"
                                           style="width: 100%"
                                           value-key="id"
                                           placeholder="请选择售后工程师">
                                    <el-option
                                            v-for="item in engineerList"
                                            :key="item.engineerName"
                                            :label="item.engineerName"
                                            :value="item.engineerName">
                                    </el-option>
                                </el-select>
                            </td>
                            <td style="width: 80px">
                                联系电话：
                            </td>
                            <td>
                                <el-input v-model="searchItem.contactTel"
                                          size="small" placeholder="请输入联系电话"></el-input>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 100px;padding-top: 10px">
                                客户名称：
                            </td>
                            <td style="padding-top: 10px;">
                                <el-input v-model="searchItem.companyName"
                                          size="small" placeholder="请输入客户名称"></el-input>
                            </td>
                            <td style="width: 100px;padding-top: 10px">
                                申请时间：
                            </td>
                            <td style="padding-top: 10px;width: 28%">
                                <el-date-picker
                                        v-model="searchItem.startDate"
                                        type="date"
                                        size="small"
                                        placeholder="选择日期时间"
                                        style="width: 47%;"
                                        class="handle-input-date"
                                        value-format="yyyy-MM-dd">
                                </el-date-picker>
                                至
                                <el-date-picker
                                        v-model="searchItem.endDate"
                                        type="date"
                                        placeholder="选择日期时间"
                                        size="small"
                                        style="width: 47%;"
                                        class="handle-input-date"
                                        value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </td>
                            <td colspan="2" style="text-align: center;padding-top: 10px">
                                <el-button type="primary" icon="el-icon-search" size="small" style="margin-right: 10%"
                                           @click="searchBtn">搜索
                                </el-button>
                                <el-button type="primary" icon="el-icon-refresh" style="margin-left: 25%" size="small"
                                           @click="reset">重置
                                </el-button>

                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="table-info">
                <div class="table-button">
                    <div>
                        <el-table
                                :data="tableData"
                                :max-height="tableHeight"
                                style="width: 100%;height: 100%;"
                                :border="true"
                                :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                :cell-style="{padding:'0',height: lineHeight}">
                            <el-table-column type="index" width="50" label="排序" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="applyNum" width="180" label="申请编号" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="companyName" label="客户名称"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="contact" width="150" label="联系人" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="contactTel" width="150" label="联系电话" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="afterSalesEngineerStr" width="150" label="售后工程师" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="createTime" width="150" label="申请时间" :formatter="dateFormat"
                                             align="center" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="finishedDate" width="150" label="结单时间"
                                             align="center" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    {{changeDateFormat(scope.row.finishedDate)}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="serviceType" width="150" label="服务类型" align="center"
                                             show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-if="scope.row.serviceType == 0">初装</span>
                                    <span v-else-if="scope.row.serviceType == 1">加装</span>
                                    <span v-else-if="scope.row.serviceType == 2">补装</span>
                                    <span v-else-if="scope.row.serviceType == 3">巡检</span>
                                    <span v-else-if="scope.row.serviceType == 4">拆卸</span>
                                    <span v-else-if="scope.row.serviceType == 5">维护</span>
                                    <span v-else-if="scope.row.serviceType == 6">远程处理</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="auditState" width="150" label="审批状态" align="center"
                                             show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-if="scope.row.auditState == -1" style="color: red">已驳回</span>
                                    <span v-else-if="scope.row.auditState == 0">草稿</span>
                                    <span v-else-if="scope.row.auditState == 10" style="color: #20a0ff">执行中</span>
                                    <span v-else-if="scope.row.auditState == 11" style="color: #39AACE">待确认</span>
                                    <span v-else-if="scope.row.auditState == 12" style="color: green">已结单</span>
                                    <span v-else style="color: blue">待审批</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="number" width="180" label="操作" align="center"
                                             show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <div class="operation">
                                        <i class="iconfont icontongguo" title="通过" @click="pass(scope.row.id)"
                                           v-if="scope.row.auditState == 1 && hasAuthority('after_approve_audit')"></i>
                                        <i class="iconfont icontongguo" title="商务确认" @click="submit(scope.row.id)"
                                           v-if="scope.row.auditState == 2 && hasAuthority('after_approve_sure')"></i>
                                        <i class="iconfont iconxiangqing" title="详情" @click="show(scope.row.id) "></i>
                                        <i class="iconfont iconbohui" title="驳回" @click="refuse(scope.row.id)"
                                           v-if="scope.row.auditState == 1 && hasAuthority('after_approve_audit')"></i>
                                        <i class="iconfont icontingzhi" title="结束" @click="end(scope.row.id,scope.row.serviceType)"
                                           v-if="scope.row.auditState == 11 && hasAuthority('after_approve_audit')"></i>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="table-data">
                    <Pagination :pageSize="option.pageSize" :pageTotal="totalPage" :pageCurrent.sync="option.pageIndex"
                                :pageList="pageList" @handlePageChange="pageChange" @handleSizeChange="pageSizeChange"/>
                </div>
            </div>
        </div>
        <div class="info-dialog">
            <el-dialog
                    :title="showTitle"
                    :visible.sync="infoDialogVisible"
                    style="margin-top: -90px"
                    width="70%"
                    :close-on-click-modal="false"
                    center>
                <div style="max-height: 650px">
                    <table width="100%">
                        <tr>
                            <td width="85px">申请编号：</td>
                            <td class="td-style">{{aftermarketDisplay.applyNum}}</td>
                            <td width="85px">合同编号：</td>
                            <td class="td-style">{{aftermarketDisplay.contractNum}}</td>
                            <td width="85px">客户名称：</td>
                            <td class="td-style">{{aftermarketDisplay.companyName}}</td>
                        </tr>
                        <tr>
                            <td width="85px">项目名称：</td>
                            <td class="td-style">{{aftermarketDisplay.projectName}}</td>
                            <td width="85px">联系人：</td>
                            <td class="td-style">{{aftermarketDisplay.contact}}</td>
                            <td width="85px">联系电话：</td>
                            <td class="td-style">{{aftermarketDisplay.contactTel}}</td>
                        </tr>
                        <tr>
                            <td width="85px">服务类别：</td>
                            <td class="td-style">
                                <span v-if="aftermarketDisplay.serviceType == 0">初装</span>
                                <span v-else-if="aftermarketDisplay.serviceType == 1">加装</span>
                                <span v-else-if="aftermarketDisplay.serviceType == 2">补装</span>
                                <span v-else-if="aftermarketDisplay.serviceType == 3">巡检</span>
                                <span v-else-if="aftermarketDisplay.serviceType == 4">拆卸</span>
                                <span v-else-if="aftermarketDisplay.serviceType == 5">维护</span>
                                <span v-else-if="aftermarketDisplay.serviceType == 6">远程处理</span>
                            </td>
                            <td width="85px">项目地址：</td>
                            <td class="td-style" colspan="3">{{aftermarketDisplay.projectAddress}}</td>
                        </tr>
                        <tr>
                            <td width="85px">现场工程师：</td>
                            <td class="td-style" colspan="3">{{aftermarketDisplay.afterSalesEngineerStr}}</td>
                            <td width="85px">是否特殊地区：</td>
                            <td class="td-style">{{aftermarketDisplay.isSpecialArea}}</td>
                        </tr>

                        <tr v-if="aftermarketDisplay.serviceType == 5||aftermarketDisplay.serviceType == 6">
                            <td width="85px">申请人：</td>
                            <td class="td-style">{{aftermarketDisplay.applyUserName}}</td>
                            <td width="85px">现场处理：</td>
                            <td class="td-style">{{aftermarketDisplay.siteUserName}}</td>
                            <td width="85px">是否保质期：</td>
                            <td class="td-style">{{aftermarketDisplay.isInWarranty}}</td>
                        </tr>
                        <tr v-if="aftermarketDisplay.serviceType == 5||aftermarketDisplay.serviceType == 6">
                            <td width="85px">售后更换方式：</td>
                            <td class="td-style">{{aftermarketDisplay.changeMethod}}</td>
                            <td width="85px">工程师：</td>
                            <td class="td-style">{{aftermarketDisplay.remoteDealUserName}}</td>
                            <td width="85px">时间：</td>
                            <td class="td-style">{{aftermarketDisplay.dealDate.split(' ')[0]}}</td>
                        </tr>
                        <tr v-if="aftermarketDisplay.serviceType == 5||aftermarketDisplay.serviceType == 6">
                            <td width="85px">故障描述：</td>
                            <td class="td-style" colspan="5">
                                {{aftermarketDisplay.trouble}}
                            </td>
                        </tr>
                        <tr v-if="aftermarketDisplay.serviceType == 5||aftermarketDisplay.serviceType == 6">
                            <td width="85px">发货信息：</td>
                            <td class="td-style" colspan="5">
                                {{aftermarketDisplay.logistic}}
                            </td>
                        </tr>
                        <tr v-if="aftermarketDisplay.serviceType == 5||aftermarketDisplay.serviceType == 6">
                            <td width="85px">远程处理结果：</td>
                            <td class="td-style" colspan="5">
                                {{aftermarketDisplay.remoteDealResult}}
                            </td>
                        </tr>
                    </table>
                    <el-table
                            :data="showDeviceList"
                            :max-height="tableHeight"
                            style="width: 99%;height: 100%;margin-top: 10px"
                            :border="true"
                            :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                            :cell-style="{padding:'0',height: lineHeight}">
                        <el-table-column type="index" width="50" label="排序" align="center"></el-table-column>
                        <el-table-column prop="deviceType" width="90" label="设备类型" align="center"
                                         show-overflow-tooltip>
                            <template slot-scope="scope">
                                <span>{{scope.row.deviceType==0?'塔机':'升降机'}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="imeiNum" label="IMEI号" align="center"
                                         show-overflow-tooltip></el-table-column>
                        <el-table-column prop="simNum" label="SIM卡号" align="center"
                                         show-overflow-tooltip></el-table-column>
                        <el-table-column prop="isVisual" width="140" label="是否吊钩可视化" align="center">
                            <template slot-scope="scope">
                                <span>{{scope.row.isVisual==0?'否':'是'}}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-table
                            v-if="aftermarketDisplay.serviceType == 5||aftermarketDisplay.serviceType == 6"
                            ref="multipleTable"
                            :data="tableList"
                            tooltip-effect="dark"
                            style="width: 100%;margin-top: 10px"
                            max-height="350"
                            border
                            :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                            :cell-style="{padding:'0',height: lineHeight}"
                    >
                        <el-table-column type="index" width="50" label="排序" align="center"></el-table-column>
                        <el-table-column prop="materialCode" label="物料编码" align="center"
                                         show-overflow-tooltip></el-table-column>
                        <el-table-column prop="useTypeName" label="适用机型" align="center"
                                         show-overflow-tooltip></el-table-column>
                        <el-table-column prop="componentName" label="部件名称" align="center"
                                         show-overflow-tooltip></el-table-column>
                        <el-table-column prop="materialName" label="产品名称" align="center"
                                         show-overflow-tooltip></el-table-column>
                        <el-table-column prop="model" label="型号" align="center"
                                         show-overflow-tooltip></el-table-column>
                        <el-table-column prop="count" label="数量" align="center"
                                         show-overflow-tooltip>
                        </el-table-column>
                    </el-table>
                </div>
                <div style="margin-top: 10px;">
                    <span style="margin-right: 20px">附件信息：</span>
                    <a style="color: blue;" :href="downloadUrl + 'erp' + aftermarketDisplay.attachmentUrl">
                        {{aftermarketDisplay.attachmentUrl != null?
                        aftermarketDisplay.attachmentUrl.split('/').slice(-1) + '':''}}</a>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="infoDialogVisible = false">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <el-dialog
                title="请确认本次派单原因"
                :visible.sync="centerDialogVisible"
                width="30%"
                :close-on-click-modal="false"
                center>
            <div style="text-align:center;">
                <template>
                    <el-radio-group v-model="dealReason">
                        <el-radio-button label="产品因素"></el-radio-button>
                        <el-radio-button label="安装因素"></el-radio-button>
                    </el-radio-group>
                </template>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitEnd" :loading="isLoad">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    var pathURL = process.env.VUE_APP_CURENV;

    export default {

        data() {
            return {
                downloadUrl: pathURL,
                pageList: [15, 20, 30, 50],
                tableHeight: '',
                centerDialogVisible: false,
                showTitle: '',
                isLoad: false,
                tableData: [],
                tableList: [],
                showDeviceList: [],
                engineerList: [],
                option: {
                    pageSize: 15,
                    pageIndex: 1,
                },
                totalPage: 10,
                infoDialogVisible: false,
                lineHeight: '',
                searchItem: {
                    applyNum: '',
                    afterSalesEngineerStr: '',
                    contactTel: '',
                    isAudit: 1,
                    companyName: '',
                    endDate: '',
                    startDate: '',
                    pageSize: 15,
                    pageIndex: 1
                },
                aftermarketDisplay: {
                    id: null,
                    applyNum: '',
                    contractId: '',
                    contractNum: '',
                    projectName: '',
                    companyName: '',
                    contact: '',
                    contactTel: '',
                    projectAddress: '',
                    serviceType: '',
                    isSpecialArea: '',
                    applyUserId: '',
                    applyUserName: '',
                    siteUserId: '',
                    siteUserName: '',
                    logistic: '',
                    trouble: '',
                    isInWarranty: '',
                    changeMethod: 1,
                    remoteDealResult: '',
                    remoteDealUserId: '',
                    remoteDealUserName: '',
                    dealDate: '',
                    attachmentUrl: '',
                    afterSalesEngineerStr: '',
                    afterSalesMaterialStr: '',
                    afterSalesDeviceStr: ''
                },
                endId: '',
                dealReason: '产品因素',
            };
        },
        methods: {
            pageChange(option) {
                if(option != "refresh"){
                    this.option.pageIndex = option;
                    this.searchItem.pageIndex = option;
                }
                this.$api.pageAftermarketList(this.searchItem).then(res => {
                    if (res.code == 200) {
                        this.totalPage = res.data.totalElements;
                        this.tableData = res.data.content;
                    }
                })
            },
            pageSizeChange(val) {
                this.searchItem.pageSize = val;
                this.$api.pageAftermarketList(this.searchItem).then(res => {
                    if (res.code == 200) {
                        this.totalPage = res.data.totalElements;
                        this.tableData = res.data.content;
                    }
                })
            },
            reset() {
                this.searchItem = {
                    applyNum: '',
                    afterSalesEngineerStr: '',
                    isAudit: 1,
                    contactTel: '',
                    companyName: '',
                    endDate: '',
                    startDate: '',
                    pageSize: 15,
                    pageIndex: 1
                }
                this.pageChange(1);
            },
            searchBtn() {
                this.pageChange(1);
            },
            show(id) {
                this.deviceList = [];
                this.$api.getAftermarketById({id: id}).then(res => {
                    if (res.code == 200) {
                        this.aftermarketDisplay = {
                            id: res.data.id,
                            applyNum: res.data.applyNum,
                            applyUserName: res.data.applyUserName,
                            siteUserName: res.data.siteUserName,
                            remoteDealUserName: res.data.remoteDealUserName,
                            contractId: res.data.contractId,
                            companyName: res.data.companyName,
                            contact: res.data.contact,
                            contactTel: res.data.contactTel,
                            projectAddress: res.data.projectAddress,
                            serviceType: res.data.serviceType,
                            isSpecialArea: res.data.isSpecialArea == 0 ? "否" : '是',
                            applyUserId: res.data.applyUserId,
                            siteUserId: res.data.siteUserId,
                            logistic: res.data.logistic,
                            trouble: res.data.trouble,
                            isInWarranty: res.data.isInWarranty == 0 ? "否" : '是',
                            changeMethod: res.data.changeMethod == 0 ? "客户自换" : '现场更换',
                            remoteDealResult: res.data.remoteDealResult,
                            remoteDealUserId: res.data.remoteDealUserId,
                            attachmentUrl: res.data.attachmentUrl,
                            dealDate: res.data.dealDate ? res.data.dealDate.split(' ')[0] : '',
                        }
                        this.showDeviceList = res.data.afterSalesDeviceList;
                        this.tableList = res.data.afterSalesMaterialList;
                        this.showTitle = '查看《' + res.data.applyNum + '》详情'
                        for (var item of this.projectList) {
                            if (JSON.stringify(item.contractList).indexOf(res.data.contractId) != -1) {
                                this.aftermarketDisplay.projectName = item.projectName;
                                this.showProjectName(item.contractList, res.data.contractId)
                                break;
                            }
                        }
                        var engineerListStr = '';
                        for (const item of res.data.afterSalesEngineerList) {
                            for (var itemObj of this.engineerList) {
                                if (engineerListStr == '' && itemObj.id == item.engineerId) {
                                    engineerListStr = itemObj.engineerName
                                } else if (itemObj.id == item.engineerId) {
                                    engineerListStr = engineerListStr + '，' + itemObj.engineerName
                                }
                            }
                        }
                        this.aftermarketDisplay.afterSalesEngineerStr = engineerListStr;
                        var materialArr = JSON.parse(this.tableListStr)
                        var materialList = [];
                        for (var item of this.tableList) {
                            for (var itemObj of materialArr) {
                                if (item.materialId == itemObj.id) {
                                    itemObj.checked = true;
                                    itemObj.count = item.count
                                    materialList.push(itemObj);
                                }
                            }
                        }
                        this.tableList = materialList;
                        this.infoDialogVisible = true;
                    }
                })

            },
            pass(id) {
                this.$confirm('你确定要通过该售后申请吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.$api.approveAftermarketById({id: id, isPass: 1}).then(res => {
                        if (res.code == 200) {
                            this.pageChange("refresh")
                            this.$message.success(res.message)
                        } else {
                            this.$message.error(res.message)
                        }
                    })
                }).catch(() => {
                })
            },
            submit(id) {
                this.$confirm('你确定要确认该售后申请吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.$api.submitAftermarketByBusiness({id: id}).then(res => {
                        if (res.code == 200) {
                            this.pageChange("refresh")
                            this.$message.success(res.message)
                        } else {
                            this.$message.error(res.message)
                        }
                    })
                }).catch(() => {
                })
            },
            refuse(id) {
                this.$confirm('你确定要驳回该售后申请吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.$api.approveAftermarketById({id: id, isPass: 0}).then(res => {
                        if (res.code == 200) {
                            this.pageChange("refresh")
                            this.$message.success(res.message)
                        } else {
                            this.$message.error(res.message)
                        }
                    })
                }).catch(() => {
                })
            },
            end(id,serviceType) {
                if (serviceType != 5){
                    this.$confirm('你确定要结束售后申请吗？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                        center: true
                    }).then(() => {
                        this.$api.endSureAftermarketById({id: id, dealReason: 0}).then(res => {
                            if (res.code == 200) {
                                this.pageChange("refresh")
                                this.$message.success(res.message)
                            } else {
                                this.$message.error(res.message)
                            }
                        })
                    }).catch(() => {
                    })
                }else {
                    this.centerDialogVisible = true;
                    this.isLoad = false;
                    this.endId = id;
                }
            },
            submitEnd() {
                this.isLoad = true;
                this.centerDialogVisible = false;
                this.$api.endSureAftermarketById({
                    id: this.endId,
                    dealReason: this.dealReason.indexOf("产品因素") != -1 ? 0 : 1
                }).then(res => {
                    if (res.code == 200) {
                        this.pageChange("refresh")
                        this.$message.success(res.message)
                        this.isLoad = false;
                    } else {
                        this.$message.error(res.message)
                        this.isLoad = false;
                    }
                })
            },
            showProjectName(val, contractId) {
                for (var item of val) {
                    if (item.id == contractId) {
                        this.aftermarketDisplay.contractNum = item.contractNum;
                    }
                }
                this.$api.getDeviceListById({contractId: contractId}).then(res => {
                    if (res.code == 200) {
                        if (res.data.length > 0) {
                            for (var item of this.showDeviceList) {
                                for (var itemObj of res.data) {
                                    if (item.deviceId == itemObj.id) {
                                        item.deviceType = itemObj.deviceType;
                                        item.deviceId = itemObj.id;
                                        item.simNum = itemObj.simNum;
                                        item.imeiNum = itemObj.imeiNum;
                                    }
                                }
                            }
                            this.$forceUpdate();
                        } else {
                            this.$message.error("该合同信息还没有进行发货操作")
                            return;
                        }
                    }
                })
            },
            initData() {
                this.$api.getEngineerList().then(res => {
                    if (res.code == 200) {
                        this.engineerList = res.data;
                    }
                });
                this.$api.getProjectList().then(res => {
                    if (res.code == 200) {
                        this.projectList = res.data;
                    }
                });
                this.$api.getMaterialList({warehouseType: 0}).then(res => {
                    if (res.code == 200) {
                        for (var item of res.data) {
                            item.checked = false;
                        }
                        this.tableList = res.data;
                        this.tableListStr = JSON.stringify(res.data);
                    } else {
                        this.$message.error(res.message)
                    }
                });
            },
            dateFormat(row, column) {
                let data = row[column.property]
                let dt = new Date(data)
                var year = dt.getFullYear();
                var month = (dt.getMonth() + 1) < 10 ? 0 + '' + (dt.getMonth() + 1) : ((dt.getMonth() + 1))
                var date = dt.getDate() < 10 ? 0 + '' + dt.getDate() : (dt.getDate())
                return year + '-' + month + '-' + date
            },
            changeDateFormat(date) {
                if (date == null || date == '' || date == undefined) {
                    return '';
                } else {
                    let dt = new Date(date)
                    var year = dt.getFullYear();
                    var month = (dt.getMonth() + 1) < 10 ? 0 + '' + (dt.getMonth() + 1) : ((dt.getMonth() + 1))
                    var date = dt.getDate() < 10 ? 0 + '' + dt.getDate() : (dt.getDate())
                    return year + '-' + month + '-' + date
                }
            },
        },
        created() {
            this.initData()
            this.pageChange(1)
            this.tableHeight = (window.innerHeight) * 0.72
            if (window.screen.height > 900) {
                this.lineHeight = window.screen.height * 0.038 + 'px';
            } else {
                this.lineHeight = window.screen.height * 0.033 + 'px';
            }
        }
    };
</script>
<style lang="scss" scoped>
    .iconfont {
        font-family: "iconfont" !important;
        font-size: 24px;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-top: 3px;
        display: block;
        cursor: pointer;
    }

    .icontongguo {
        color: green;
        margin-right: 20px;
    }

    .icontingzhi {
        color: red;
        margin-left: 20px;
    }

    .iconbohui {
        color: red;
        margin-left: 20px;
    }

    .button-style {
        color: blue;
        cursor: pointer;
        text-decoration: underline;
        width: 60px;
    }

    .upload-file {
        display: flex;
        flex-direction: row;
    }

    .page-body {
        background-color: #FFFFFF;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .table-body {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .table-header {
        height: 90px;
        margin-top: 0.5%;
        background-color: #FFFFFF;
    }

    .handle-box {
        padding: 5px;
    }

    .table-info {
        height: 94%;
        margin-top: 5px;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
    }

    .table-button {
        flex: 1;
        margin: 0 1%;
    }

    .table-data {
        height: 6%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .operation {
        display: flex;
        justify-content: center;
    }

    td {
        text-align: right;
        white-space: nowrap; /*控制单行显示*/
        overflow: hidden; /*超出隐藏*/
        text-overflow: ellipsis; /*隐藏的字符用省略号表示*/
    }

    .info-dialog td {
        padding-top: 10px;
        text-align: left;
    }

    .td-style {
        width: 30%;
        white-space: normal; /*控制单行显示*/
        overflow: hidden; /*超出隐藏*/
        text-overflow: ellipsis; /*隐藏的字符用省略号表示*/
    }

    .edit-body {
        max-height: 650px;
        overflow-y: auto;
    }

    .material-table, .table-material {
        margin-top: 10px;
    }
</style>